import React from 'react'
import { t_body } from '../../../locales/es-es'

const InputSimple = ({ title, type, name, value, onChange, defaultData, handleBlur, placeholder, errorText, messageAge, animation, errorAnimation, animationPlacement, animationDuration }) => {

  return (
    <div data-aos={animation} data-aos-anchor-placement={animationPlacement} data-aos-duration={animationDuration} className='w-full flex flex-col relative'>
      <label htmlFor="first-name" className="block text-lg font-medium text-gray-900">
        {title}
      </label>
      <div className="mt-1">
        <input
          type={type}
          name={name}
          id="first-name"
          onBlur={handleBlur}
          value={value}
          onChange={onChange}
          defaultValue={defaultData}
          placeholder={placeholder}
          className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
        />
      </div>
      {errorText && <span data-aos={errorAnimation} className='text-red-500 absolute bottom-[-30px] pt-4 uppercase text-[10px] font-bold'>{errorText} </span>}
      {messageAge > 0 && !errorText && <span data-aos={errorAnimation} className='text-green-500 absolute bottom-[-30px] uppercase text-[12px] font-bold'> {t_body.edad} : {messageAge} {t_body.anos} </span>}
    </div>
  )
}

export default InputSimple