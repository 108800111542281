import { regexName, regexEmail, regexNumber } from "../../helper/regExp"
import { errorValidations } from "../../locales/es-es"

export const validateStep1 = (form, dataForm, phone) => {
    let error = {}
    if (!form.name.trim()) {
        error.name = errorValidations.campo
    } else if (!regexName.test(form.name.trim())) {
        error.name = errorValidations.name
    }
    if (!form.cedula.trim()) {
        error.cedula = errorValidations.campo
    }
    if (!form.email.trim()) {
        error.email = errorValidations.campo
    } else if (!regexEmail.test(form.email.trim())) {
        error.email = errorValidations.email
    }
    /*  if (!form.phone.trim()) {
         error.phone = errorValidations.campo
     } */
    if (!form.sex.trim()) {
        error.sex = errorValidations.campo
    }
    /*  if (!form?.salud.trim()) {
         error.salud = errorValidations.campo
     } */
    if (!form.date) {
        error.date = errorValidations.campo
    } else if (dataForm?.ageNow <= 0 || dataForm?.ageNow > 75) {
        error.date = errorValidations.date
    }

    if (!form.phone || form.phone.length <= 8) {
        error.phone = errorValidations.campo
    }


    return error
}
export const validateStep2 = (form, dataForm) => {
    let error = {}
    if (!form?.salud) {
        error.salud = true
    }
    /* if (!form?.inputSalud) {
        error.inputSalud = 'Campo Obligatorio'
    } */
    return error
}
export const validateStep3 = (form, dataForm) => {
    let error = {}
    if (!form?.inputSalud) {
        error.inputSalud = 'Campo Obligatorio'
    }
    return error
}
/* export const validateStep3 = (form, dataForm) => {
    let error = {}



    return error
} */