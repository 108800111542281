import { useEffect, useState } from 'react';
import { AuthContext } from './context/StateStep';
import AppRoutes from './routes/AppRoues';
import { getDate } from './utils/firebase/actions';

function App() {
  const [stepsState, setStepsState] = useState({ option1: true, option2: false, option3: false })
  const [dataForm, setDataForm] = useState('')
  const [optionsBank, setOptionsBank] = useState([''])
  const [optionsAsegura, setOptionsAsegura] = useState([''])
  const [dataBase, setDataBase] = useState([])

  const data = async () => {
    const result = await getDate('insurers')
    if (result.status) {
      setDataBase([...result.products])
    } else {
      console.log('result', result.error)
    }
  }

  useEffect(() => {
    data()
  }, [])

  return (
    <>
      <AuthContext.Provider value={[stepsState, setStepsState, dataForm, setDataForm, optionsBank, setOptionsBank, optionsAsegura, setOptionsAsegura, dataBase, setDataBase]}>
        <AppRoutes />
      </AuthContext.Provider>
    </>
  );
}

export default App;
