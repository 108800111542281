import { useContext } from "react"
import { AuthContext } from "../../context/StateStep"
import { parseNumber } from "../../helper/parseNumber";


export const ValidateAges = () => {
    const [stepsState, setStepsState, dataForm, setDataForm, optionsBank, setOptionsBank, optionsAsegura, setOptionsAsegura, dataBase, setDataBase] = useContext(AuthContext)


    const seguroEstar = dataBase[0];
    const seguroCaracas = dataBase[1];
    const seguroMercantil = dataBase[2];

    const validateMercantil = () => {
        var BreakException = {};
        try {
            seguroMercantil.policies_age.forEach(function (element, index) {
                const respon = seguroMercantil.policies_cost[index]
                const sumaSeguro0 = parseNumber(seguroMercantil.policies[0])
                const sumaSeguro1 = parseNumber(seguroMercantil.policies[1])
                const sumaSeguro2 = parseNumber(seguroMercantil.policies[2])
                const p0 = parseNumber(respon[0])
                const p1 = parseNumber(respon[1])
                const p2 = parseNumber(respon[2])
                const result = [
                    { id: 0, sumaAsegurada: sumaSeguro0, montoXMes: respon[0] / 12, montoTriMes: respon[0] / 4, montoXAno: p0 },
                    { id: 1, sumaAsegurada: sumaSeguro1, montoXMes: respon[1] / 12, montoTriMes: respon[1] / 4, montoXAno: p1 },
                    { id: 2, sumaAsegurada: sumaSeguro2, montoXMes: respon[2] / 12, montoTriMes: respon[2] / 4, montoXAno: p2 },
                ]
                setOptionsAsegura(result)
                if (dataForm.ageNow <= element) throw BreakException;
            });
        } catch (e) {
            if (e !== BreakException) throw e;
        }
    }
    const validateEstar = () => {
        var BreakException = {};

        try {
            seguroEstar.policies_age.forEach(function (element, index) {
                const respon = seguroEstar.policies_cost[index]
                const sumaSeguro0 = parseNumber(seguroEstar.policies[0])
                const sumaSeguro1 = parseNumber(seguroEstar.policies[1])
                const p0 = parseNumber(respon[0])
                const p1 = parseNumber(respon[1])
                const result = [
                    { id: 0, sumaAsegurada: sumaSeguro0, montoXMes: respon[0] / 12, montoTriMes: respon[0] / 4, montoXAno: p0 },
                    { id: 1, sumaAsegurada: sumaSeguro1, montoXMes: respon[1] / 12, montoTriMes: respon[1] / 4, montoXAno: p1 },
                ]
                setOptionsAsegura(result)
                if (dataForm.ageNow <= element) throw BreakException;
            });
        } catch (e) {
            if (e !== BreakException) throw e;
        }
    }
    const validateCaracas = () => {
        var BreakException = {};
        try {
            seguroCaracas.policies_age.forEach(function (element, index) {
                const respon = seguroCaracas.policies_cost[index]
                const sumaSeguro0 = parseNumber(seguroCaracas.policies[0])
                const sumaSeguro1 = parseNumber(seguroCaracas.policies[1])
                const sumaSeguro2 = parseNumber(seguroCaracas.policies[2])
               
                const p0 = parseNumber(respon[0])
                const p1 = parseNumber(respon[1])
                const p2 = parseNumber(respon[2])
                
                const result = [
                    { id: 0, sumaAsegurada: sumaSeguro0, montoXMes: respon[0] / 12, montoTriMes: respon[0] / 4, montoXAno: p0 },
                    { id: 1, sumaAsegurada: sumaSeguro1, montoXMes: respon[1] / 12, montoTriMes: respon[1] / 4, montoXAno: p1 },
                    { id: 2, sumaAsegurada: sumaSeguro2, montoXMes: respon[2] / 12, montoTriMes: respon[2] / 4, montoXAno: p2 },
                    
                ]
                setOptionsAsegura(result)
                if (dataForm.ageNow <= element) throw BreakException;
            });
        } catch (e) {
            if (e !== BreakException) throw e;
        }
    }

    return { validateMercantil, validateEstar, validateCaracas }
}