import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cotizacion from '../view/Cotizacion';
import Header from '../componentes/Header/Header'

function AppRoutes() {
    return (
        <>
            <BrowserRouter>
                <Header />
                <Routes>
                    <Route path="/" element={<Cotizacion />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default AppRoutes;