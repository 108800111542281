import React, { useEffect } from 'react'

const ButtonSteps = ({ netxSteps, nameButton, color, disableButton }) => {
    return (
        <button
            type='submit'
            onClick={netxSteps}
            disabled={disableButton === undefined ? false : disableButton}
            className={`${disableButton === true ? 'bg-[#6366f1] ' : color !== undefined ? color : 'bg-[#0f37ed] hover:bg-[#08239b] '}  w-full uppercase inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
        >
            {nameButton}
        </button>
    )
}

export default ButtonSteps