import React, { useContext, useEffect, useState } from 'react'
import ButtonSteps from '../../componentes/Elements/buttons/ButtonSteps';
import { AuthContext } from '../../context/StateStep';
import { t_Button } from '../../locales/es-es';
import Resume from '../../componentes/View/Resume';
import { parseNumber } from '../../helper/parseNumber';
import { sendDate } from '../../utils/firebase/actions';
import { useNavigate } from "react-router-dom";

const Step4 = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useContext(AuthContext)
  const { 
    stepsState, 
    dataForm, 
    optionsBank, 
    optionsAsegura,
    setStepsState,
    setOptionsBank,
    setOptionsAsegura,
    dataBase,
    setDataBase, } = useContext(AuthContext);
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(false)

  const sendDataFirebase = async () => {
     setLoading(true)

     console.log("🚀 ~ sendDataFirebase ~ dataForm:", dataForm)
     const res = await sendDate('quotation', dataForm);

     if (res.statusRespon) {
 
 
         setMessage(true)
         setTimeout(() => {
           setLoading(false)
           dispatch({ type: 'CLEAR_FORM' })
           navigate("/");
         }, 5000);
     } else {
      setLoading(false)
     }
  }
 
  useEffect(() => {
  }, [])
 

  return (
    <div>
      <Resume sendDataFirebase={sendDataFirebase} loading={loading} message={message} />
    </div>
  )
}

export default Step4