import { useContext } from 'react'
import { AuthContext } from '../../context/StateStep'
import { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import ButtonSteps from '../Elements/buttons/ButtonSteps';
import { t_Button, textStep1 } from '../../locales/es-es';
import Spinner from '../Elements/loading/spinner';
import './shadow.css'
import { InitialState } from '../../propState/InitialState';

const Resume = ({ sendDataFirebase, loading, message }) => {
    const { initialForm } = InitialState()
    const [stepsState, setStepsState, dataForm, setDataForm, optionsBank, setOptionsBank, optionsAsegura, setOptionsAsegura] = useContext(AuthContext)

    const products = [
        {
            id: 1,
            name: dataForm.banco.title,
            href: '#',
            price: dataForm.aseguradora.sumaAsegurada,
            color: 'Poliza de salud',
            size: 'Monto Asegurado',
            imageSrc: dataForm.banco.img,
            imageAlt: "Aseguradora",
        }
    ]
    const backPaso3 = (e) => {
        e.preventDefault();
        setDataForm('')
        setStepsState({ ...stepsState, option1: true, option2: false, option3: false, })
    }
    useEffect(() => {
        AOS.init();
        console.log("LOADING?? ", loading);
    }, [])
    return (
        <main className="relative lg:h-screen">
            <div className="h-80 bg-[#e5e5e554] overflow-hidden p-0 lg:absolute lg:w-1/2 lg:h-full  xl:p-0">
                <img
                    src="https://tailwindui.com/img/ecommerce-images/confirmation-page-06-hero.jpg"
                    alt="TODO"
                    className="h-full w-full object-center object-cover"
                />
            </div>
            <div className="max-w-2xl h-full mx-auto lg:p-0 sm:px-6 sm:py-24 lg:max-w-7xl  relative lg:grid lg:grid-cols-2 lg:gap-x-8 xl:gap-x-24">
                {
                    loading ? (
                        <>

                            <div className="lg:col-start-2 h-full flex justify-center items-center ">
                                {
                                    message ? (<>
                                        <div className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50  dark:text-green-400" role="alert">
                                            <span className="font-medium">Solicitud enviada exitosamente</span>
                                        </div>

                                    </>) : (<> <Spinner /></>)
                                }

                            </div></>
                    ) : (
                        <>
                            <div className="lg:col-start-2 lg:place-content-between lg:grid">

                                <p className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                                    Proceso Online de Cotización
                                </p>
                                <p className="mt-2 text-base text-gray-500">
                                    Acabas de culminar tu proceso online de cotización de pólizas de seguros de tu interés. Como pudiste ver tienes un comparativo, que te permitirá seleccionar la mejor opción de acuerdo a tus necesidades y presupuesto. Para continuar con el proceso nuestro equipo de Ventas se pondrá en contacto contigo. Gracias
                                </p>

                                <dl className="mt-16 text-sm font-medium">
                                    <dt className="text-gray-900 text-[17px]">Número de cotizacion</dt>
                                    <dd className="mt-2 text-indigo-600">{dataForm?.idQuotation} </dd>
                                </dl>
                                <h2 className='mt-4 text-[17px] font-medium' >Datos personales</h2>
                                <dl className="mt-4 grid grid-cols-2 gap-x-4 text-sm text-gray-600">
                                    <div>
                                        <dd>
                                            <span className="block">  <span className='text-[15px] pt-2 text-black font-semibold'>{textStep1.nombre}:</span> {dataForm.name}</span>
                                            <span className="block"> <span className='text-[15px] pt-2 text-black font-semibold'>{textStep1.cedula}:</span> {dataForm.cedula} </span>
                                            <span className="block"> <span className='text-[15px] pt-2 text-black font-semibold'>{textStep1.email}:</span>  {dataForm.email}</span>
                                        </dd>
                                    </div>
                                    <div>
                                        <span className="block"> <span className='text-[15px] pt-2 text-black font-semibold' >{textStep1.date}:</span> {dataForm.date}</span>
                                        <span className="block"> <span className='text-[15px] pt-2 text-black font-semibold' >{textStep1.phone}:</span> {dataForm.phone.usePhone}</span>
                                        <span className="block"> <span className='text-[15px] pt-2 text-black font-semibold' >Sexo: </span>  {dataForm.sex}</span>
                                    </div>

                                </dl>
                                {
                                    dataForm.salud && (
                                        <div>  <span className="block"> <span className='text-[15px] pt-2 text-black font-semibold' >{textStep1.salud}:</span> {dataForm.salud}</span> </div>
                                    )
                                }
                                {
                                    dataForm.inputSalud && (
                                        <div>  <span className="block"> <span className='text-[15px] pt-2 text-black font-semibold' >{textStep1.noSalud}</span> {dataForm.inputSalud}</span> </div>
                                    )
                                }


                                <ul role="list" className="mt-6 text-sm font-medium text-gray-500 border-t border-gray-200 divide-y divide-gray-200"
                                >
                                    {products.map((product) => (
                                        <li key={product.id} className="flex py-6 space-x-6">
                                            <img
                                                src={product.imageSrc}
                                                alt={product.imageAlt}
                                                className="flex-none w-24 p-[9px] h-24 bg-white shadow_class  rounded-md object-center object-contain"
                                            />
                                            <div className="flex-auto space-y-1 flex flex-col justify-center">
                                                <h3 className="text-gray-900">
                                                    <span>{product.name}</span>
                                                </h3>
                                                <p>{product.color}</p>
                                                <p>{product.size}</p>
                                            </div>
                                            <div className='flex justify-center items-center '>
                                                <p className="flex-none font-medium text-gray-900">${product.price}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>

                                <dl className="text-sm font-medium text-gray-500 space-y-6 border-t border-gray-200 pt-6">
                                    <div className="flex justify-between">
                                        <dt>Mensual</dt>
                                        <dd className="text-gray-900">${dataForm?.aseguradora?.montoXMes?.toFixed(0)} </dd>
                                    </div>
                                    <div className="flex justify-between">
                                        <dt>Trimestral</dt>
                                        <dd className="text-gray-900">${dataForm?.aseguradora?.montoTriMes?.toFixed(0)} </dd>
                                    </div>

                                    <div className="flex items-center justify-between border-t border-gray-200 text-gray-900 pt-6">
                                        <dt className="text-base">Monto Anual</dt>
                                        <dd className="text-base">${dataForm?.aseguradora?.montoXAno}</dd>
                                    </div>
                                </dl>

                                <div className='w-full flex justify-between items-center pt-5 ' >

                                    <ButtonSteps netxSteps={backPaso3} nameButton={t_Button.otros} />

                                </div>

                            </div>
                        </>
                    )
                }


            </div>
        </main>
    )
}

export default Resume
