import React from 'react'
import './spinner.css'

const Spinner = () => {
    return (
        <div className='grid place-items-center '>
            <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <span>Enviando solcitud...</span>
        </div>

    )
}

export default Spinner