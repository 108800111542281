import React, { useContext, useEffect, useState, } from "react";
import { RadioGroup } from "@headlessui/react";
import ButtonSteps from "../../componentes/Elements/buttons/ButtonSteps";
import { AuthContext } from "../../context/StateStep";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { t_Button, textStep1 } from "../../locales/es-es";
import AOS from "aos";
import "aos/dist/aos.css";
import { ValidateAges } from "../../utils/validations/validateAges";
import { v4 as uuidv4 } from "uuid";
import { infoEstar, infoMercantil, infoSeguroCaracas } from "../../propState/bankOptions";
import { sendDate, sendCustomEmail } from '../../utils/firebase/actions';
import { useNavigate } from "react-router-dom";
import Spinner from '../../componentes/Elements/loading/spinner';
import ReactDOMServer from 'react-dom/server';

const Step3 = () => {
  const [
    stepsState,
    setStepsState,
    dataForm,
    setDataForm,
    optionsBank,
    setOptionsBank,
    optionsAsegura,
    setOptionsAsegura,
    dataBase,
    setDataBase,
  ] = useContext(AuthContext);
  const [state, dispatch] = useContext(AuthContext)
  const navigate = useNavigate();

  const indexSelect = dataForm?.aseguradora?.aseguradoraIndex
    ? dataForm.aseguradora.aseguradoraIndex
    : 0;
  const indexBank = dataForm?.banco?.bancoIndex
    ? dataForm?.banco?.bancoIndex
    : 0;

  const { validateMercantil, validateEstar, validateCaracas } = ValidateAges();
  const [selected, setSelected] = useState(optionsAsegura[indexSelect]);
  const [bankSelect, setBankSelect] = useState(optionsBank[indexBank]);
  const [errorOptionBank, setErrorOptionBank] = useState(false);
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false);
  const [disableButtons, setDisableButton] = useState(false);


  const sendBanco = () => {
    const idQuotation = uuidv4();
    const { price, title, turnaround, id, img } = bankSelect;
    const { sumaAsegurada, montoXMes, montoTriMes, montoXAno } = selected;
    console.log("🚀 ~ sendBanco ~ selected:", selected)
    let obj = {
      ...dataForm,
      inputSalud: '',
      salud: '',
      idQuotation,
      banco: {
        price,
        title,
        turnaround,
        bancoIndex: id,
        img,
      },
      aseguradora: {
        sumaAsegurada,
        montoXMes,
        montoXAno,
        montoTriMes,
        aseguradoraIndex: selected.id,
      },
    }

    console.log("🚀 ~ sendBanco ~ obj:", obj)

    setDataForm(obj);

    sendDataFirebase(obj);
  }

  const sendDataFirebase = async (data) => {
    console.log("🚀 ~ sendDataFirebase ~ data:", data)
    setLoading(true)
    setDisableButton(true)

    const res = await sendDate('quotation', data)
    console.log("🚀 ~ sendDataFirebase ~ res:", res)

    if (res.statusRespon) {
      setMessage(true)

      await sendCustomEmail(dataForm.email, 'Solicitud de Cotización', emailTemplate(data)).then((dataSend) => {
        console.log("🚀 ~ sendCustomEmail ~ data:", dataSend)
      });

      setTimeout(() => {
        setLoading(false)
        setDisableButton(false)
        setSelected()
        dispatch({ type: 'CLEAR_FORM' })
        setStepsState({ ...stepsState, option1: false, option2: false, option3: true, })
      }, 2000);
    } else {
      setLoading(false)
      setDisableButton(false)
    }
  }

  const finalStep = () => {
    console.log("🚀 ~ finalStep ~ selected:", selected)
    if (selected == '' || selected == undefined) {
      return setErrorOptionBank(true);
    }

    sendBanco();

  }

  const emailTemplate = (data) => {
    const product = 
      {
        id: 1,
        name: data.banco.title,
        href: '#',
        price: data.aseguradora.sumaAsegurada,
        color: 'Poliza de salud',
        size: 'Monto Asegurado',
        imageSrc: data.banco.img,
        imageAlt: "Aseguradora",
      }
    
    let stringHtml = ReactDOMServer.renderToStaticMarkup(
      <div >

        <h2 style={{"marginTop":"0.5rem", "lineHeight":"2.5rem","fontWeight":"800","fontSize": ": 22px","letterSpacing":"-0.025em","color":"#111827"}} >
          Proceso Online de Cotización
        </h2>
        <p style={{"marginTop":"0.5rem","fontSize":"1rem","lineHeight":"1.5rem","color":"#6B7280"}} >
          Acabas de culminar tu proceso online de cotización de pólizas de seguros de tu interés. Como pudiste ver tienes un comparativo, que te permitirá seleccionar la mejor opción de acuerdo a tus necesidades y presupuesto. Para continuar con el proceso nuestro equipo de Ventas se pondrá en contacto contigo. Gracias
        </p>

        <dl style={{"marginTop":"2rem","fontSize":"0.875rem","lineHeight":"1.25rem","fontWeight":"500"}}>
          <dt style={{"color":"17px", "fontSize":"18px",}}>Número de cotizacion</dt>
          <dt style={{"marginTop":"0.5rem","color":"#4F46E5"}}>{data?.idQuotation} </dt>
        </dl>
        <h2 style={{"marginTop":"1rem","fontWeight":"500","color":"17px"}} >Datos personales</h2>
        <dl style={{"display":"grid \nmargin-top: 1rem","gridTemplateColumns":"repeat(2, minmax(0, 1fr))","columnGap":"1rem","fontSize":"0.875rem","lineHeight":"1.25rem","color":"#4B5563"}}>
          <div>
            
              <span style={{"display":"block"}}>  <span style={{"paddingTop":"0.5rem","fontWeight":"700","color":"15px"}}>{textStep1.nombre}:</span> {data.name}</span>
              <span style={{"display":"block"}}> <span style={{"paddingTop":"0.5rem","fontWeight":"700","color":"15px"}}>{textStep1.cedula}:</span> {data.cedula} </span>
              <span style={{"display":"block"}}> <span style={{"paddingTop":"0.5rem","fontWeight":"700","color":"15px"}}>{textStep1.email}:</span>  {data.email}</span>
            
          </div>
          <div>
            <span style={{"display":"block"}}> <span style={{"paddingTop":"0.5rem","fontWeight":"700","color":"15px"}} >{textStep1.date}:</span> {data.date}</span>
            <span style={{"display":"block"}}> <span style={{"paddingTop":"0.5rem","fontWeight":"700","color":"15px"}} >{textStep1.phone}:</span> {data.phone.usePhone}</span>
            <span style={{"display":"block"}}> <span style={{"paddingTop":"0.5rem","fontWeight":"700","color":"15px"}} >Sexo: </span>  {data.sex}</span>
          </div>

        </dl>


        <ul style={{"marginTop":"1.5rem","borderTop":"solid 1px #e5e7eb", "fontSize":"0.875rem","fontWeight":500,"color":"#6B7280"}}
        >
          
          <div key={product.id} style={{"display":"flex","paddingTop":"1.5rem","paddingBottom":"1.5rem"}}>
            <div style={{"border":"solid 1px #E5E7EB","borderRadius":"6px", "boxShadow": "gray 2px 2px 2px"}}> 
              <img
                src={product.imageSrc}
                alt={product.imageAlt}
                style={{"objectFit":"contain","objectPosition":"center","flex":"none","borderRadius":"0.375rem","width":"6rem","height":"6rem","backgroundColor":"#ffffff","padding":"9px"}}
              />
             </div>
              <div style={{"marginTop":"0.25rem","flexDirection":"column","flex":"1 1 auto","justifyContent":"center", "marginLeft": "20px", "width": "100%"}}>
                <h3 style={{"color":"#111827"}}>
                  <span>{product.name}</span>
                </h3>
                
                <div style={{"display":"flex","justifyContent":"center","alignItems":"center", "width": "100%"}}>
                  <p style={{ "width": "70%"}}>{product.color}</p>
                  <p style={{"flex":"none","fontWeight":500,"color":"#111827"}}>${product.price}</p>
                </div>
                <p>{product.size}</p>
              </div>
              
            </div>
          
        </ul>

        <dl style={{"paddingTop":"1.5rem","marginTop":"1.5rem","borderTop":"solid 1px #E5E7EB", "fontSize":"0.875rem","lineHeight":"1.25rem","fontWeight":500,"color":"#6B7280"}}>
          <div style={{"display":"flex","justifyContent":"space-between", "width": "100%",}}>
            <dt style={{"width": "70%",}}>Mensual</dt>
            <dd style={{"color":"#111827"}}>${data?.aseguradora?.montoXMes?.toFixed(0)} </dd>
          </div>
          <div style={{"display":"flex","justifyContent":"space-between", "width": "100%", "paddingBottom":"20px"}}>
            <dt style={{"width": "70%",}}>Trimestral</dt>
            <dd style={{"color":"#111827"}}>${data?.aseguradora?.montoTriMes?.toFixed(0)} </dd>
          </div>

          <div style={{"display":"flex","paddingTop":"1.5rem","justifyContent":"space-between", "width": "100%","alignItems":"center", "borderTop":"solid 1px #E5E7EB",  "color":"#111827"}}>
            <dt style={{"fontSize":"1rem","lineHeight":"1.5rem", "width": "70%"}}>Monto Anual</dt>
            <dd style={{"fontSize":"1rem","lineHeight":"1.5rem"}}>${data?.aseguradora?.montoXAno}</dd>
          </div>
        </dl>
      </div>

    );
    return stringHtml.toString();
  }

  useEffect(() => {
    AOS.init();
  }, [bankSelect]);

  useEffect(() => {
    if (bankSelect.title === "Estar Seguros") {
      validateEstar(infoEstar);
      setMessage()
    }
    if (bankSelect.title === "Seguros Caracas") {
      validateCaracas(infoSeguroCaracas);
      setMessage()
    }
    if (bankSelect.title === "Mercantil Seguros") {
      setMessage()
      validateMercantil(infoMercantil);
    }
  }, [bankSelect]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div>
      <div className='grid gap-12'>
        {loading ?
          (<Spinner />) : (<>
            <div className="">
              <RadioGroup value={bankSelect} onChange={(e) => { setBankSelect(e); setSelected() }}>
                <RadioGroup.Label className="text-lg font-medium text-gray-900">
                  Seleccionar Aseguradora
                </RadioGroup.Label>
                <div className="mt-4 grid grid-cols-1 lg:grid-cols-3 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                  {optionsBank.map((ban, index) => (
                    <RadioGroup.Option
                      key={index}
                      value={ban}
                      className={({ checked, active }) =>
                        classNames(
                          checked ? "border-transparent bg-[#eef2ff] " : "border-gray-300",
                          active ? "ring-2 ring-[#0f37ed] " : "",
                          "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
                        )
                      }
                    >
                      {({ checked, active }) => (
                        <>
                          <div className="flex-1 flex">
                            <div className="flex h-[100px] w-full justify-center items-center ">
                              <RadioGroup.Label
                                as="span"
                                className="block text-lg font-medium text-gray-900"
                              >
                                {/* {ban.title} */}
                                <img src={ban.img} alt={ban.title} width="200px" />
                              </RadioGroup.Label>

                              <RadioGroup.Description
                                as="span"
                                className="mt-6 text-lg font-medium text-gray-900"
                              >
                                {ban.price}
                              </RadioGroup.Description>
                            </div>
                          </div>
                          {checked ? (
                            <CheckCircleIcon
                              className="h-5 w-5 text-[#0f37ed] "
                              aria-hidden="true"
                            />
                          ) : null}
                          <div
                            className={classNames(
                              active ? "border" : "border-2",
                              checked ? "border-[#0f37ed] " : "border-transparent",
                              "absolute -inset-px rounded-lg pointer-events-none"
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>

            <RadioGroup value={selected} onChange={(e) => { setSelected(e); setErrorOptionBank(false) }}>
              <RadioGroup.Label className="sr-only">Planes</RadioGroup.Label>
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                className="relative bg-white grid gap-5 rounded-md -space-y-px"
              >
                {optionsAsegura.map((plan, planIdx) => (
                  <RadioGroup.Option
                    key={planIdx}
                    value={plan}
                    className={({ checked }) =>
                      classNames(
                        planIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                        planIdx === optionsAsegura.length - 1
                          ? "rounded-bl-md rounded-br-md"
                          : "",
                        checked
                          ? "bg-indigo-50 border-indigo-200 z-10"
                          : "border-gray-200",
                        "relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-2 focus:outline-none"
                      )
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <div className="flex items-center text-lg">
                          <span
                            className={classNames(
                              checked
                                ? "bg-[#0f37ed] border-transparent"
                                : "bg-white border-gray-300",
                              active ? "ring-2 ring-offset-2 ring-[#0f37ed] " : "",
                              "h-4 w-4 rounded-full border flex items-center justify-center"
                            )}
                            aria-hidden="true"
                          >
                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <RadioGroup.Label
                            as="span"
                            className={classNames(
                              checked ? "text-[#0f37ed] " : "text-gray-400",
                              "ml-3 font-medium"
                            )}
                          >
                            ${plan.sumaAsegurada}
                          </RadioGroup.Label>
                        </div>
                        <RadioGroup.Description className="ml-6 pl-1 text-lg md:ml-0 md:pl-0 md:text-center">
                          <span className={classNames(checked ? 'text-[#0f37ed] ' : 'text-gray-400', 'font-medium')}>
                            ${plan?.montoXMes?.toFixed(0)} / Mes
                          </span>{' '}
                          {/*   <span className={checked ? 'text-indigo-700' : 'text-gray-500'}>(${plan.priceYearly} / yr)</span> */}
                        </RadioGroup.Description>

                      </>
                    )}
                  </RadioGroup.Option>
                ))}
                {errorOptionBank && <span data-aos='fade-up' className='text-red-500  bottom-[-30px] pt-4 uppercase text-[10px] font-bold'> Elija una opción </span>}
              </div>
            </RadioGroup> </>)
        } 
      </div>
      <div className="sm:col-span-2 w-1/3 float-right mt-7">
        <ButtonSteps netxSteps={() => finalStep()} nameButton={t_Button.accept} disableButton={disableButtons} />
      </div>
    </div>
  );
};

export default Step3;
