import React, { useContext, useState } from 'react'
import { AuthContext } from '../context/StateStep'
import { calculateAge } from '../helper/CalculateAge'

export const useForm = (initialForm, validateForm, validateStep2) => {
    const [form, setForm] = useState(initialForm)
    const [errors, setErrors] = useState('')
    const [stepsState, setStepsState, dataForm, setDataForm, optionsBank, setOptionsBank, optionsAsegura, setOptionsAsegura] = useContext(AuthContext)
    const netxSteps = () => { setStepsState({ ...stepsState, option1: false, option2: true, option3: false, }) }
    const [dob, setDob] = useState('')

    const handleChange = (e, label = '') => {
       
        const { name, value } = e.target;
        if (name === 'sex') {
            setDataForm({ ...dataForm, sex: value })
        }
        if (name === 'salud') {
            setDataForm({ ...dataForm, salud: value })
        }
        if(label === 'phone'){
            setForm({
                ...form,
                phone: value
            })
            console.log(value);
        }else{
            setForm({
                ...form,
                [name]: value
            })
        }
        if (e.target.name === 'date') {
            const result = calculateAge(e.target.value)
            setDataForm({ ageNow: result })
        }
    }
    const handleBlur = (e, label = '') => {
        // handleChange(e, label)
        setErrors(validateForm(form, dataForm))
    }
    const handleSubmit = (e, string, phone) => {
        console.log('message 0');
        console.log('string', string);
        console.log('message 1');
        e.preventDefault();
        if (string === 'step 1') {
            let { ageNow } = dataForm
            let { name, cedula, email, sex, date, salud } = form
            let newData = {
                ageNow,
                name,
                cedula,
                email,
                sex,
                date,
                salud,
            }
            setDataForm({
                ...dataForm,
                name: newData.name,
                cedula: newData.cedula,
                email: newData.email,
                phone: phone,
                sex: newData.sex,
                date: newData.date,
                salud: newData.salud,

            })
            setErrors(validateForm(form, dataForm, phone))
        } else if (string === 'step 3'){
            console.log('string', string);
        }
        if (string === 'step 2') {
            setDataForm({
                ...dataForm,
                salud: form?.salud,
                inputSalud: form?.inputSalud
            })
            setErrors(validateForm(form, dataForm))

        }
        if (errors === '') {
            return 'espere'
        } else if (Object.keys(errors).length === 0) {
            return 'ok'
        }
    }

    return { form, setForm, errors, handleChange, handleSubmit, handleBlur }
}
