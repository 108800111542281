import React from 'react'

function InputTypeRadio({ label, type, onChange, value, id, name, htmlFor, handleBlur, error, defaultValue, animation, animationPlacement, animationDuration }) {

    return (
        <div data-aos={animation} data-aos-anchor-placement={animationPlacement} data-aos-duration={animationDuration}>
            <input
                onBlur={handleBlur}
                onChange={onChange}
                className="sr-only peer"
                type={type}
                value={value}
                name={name}
                id={id} />
            <label className={`
            flex p-5 
            bg-white 
            ${error ? 'border-red-400 ' : 'border-gray-300 '} 
            border 
             rounded-lg 
            cursor-pointer 
            focus:outline-none 
            ${defaultValue === label ? 'border-[#6366f1] border-[2px] ' : 'border-gray-300 '} 
            hover:border-[#6366f1]
            hover:bg-[#d8d8ff7e]
            peer-checked:ring-[#6366f1]
            peer-checked:ring-2 
            peer-checked:border-transparent`}
                htmlFor={htmlFor}>
                {label}
            </label>
        </div>
    )
}

export default InputTypeRadio