import { useContext } from "react"
import { AuthContext } from "../context/StateStep"

export const InitialState = () => {
    const [stepsState, setStepsState, dataForm, setDataForm, optionsBank, setOptionsBank, optionsAsegura, setOptionsAsegura] = useContext(AuthContext)
    const initialForm = {
        name: dataForm?.name || '',
        cedula: dataForm?.cedula || '',
        email: dataForm?.email || '',
        phone: dataForm?.phone || '',
        sex: dataForm?.sex || '',
        date: dataForm?.date || '',

    }

    const initialForm2 = {
        salud: dataForm?.salud || '',
        inputSalud: dataForm?.inputSalud || ''
    }


    return { initialForm, initialForm2 }
}
