export const infoMercantil = {
    name:'Beneficio',
    Servicio: 'Servicio Mercantil 1',
    Servicio2: 'Servicio Mercantil 2',
    Servicio3: 'Servicio Mercantil 3',
    Servicio4: 'Servicio Mercantil 4',
    Servicio5: 'Servicio Mercantil 5',
    Servicio6: 'Servicio Mercantil 6',
}
export const infoEstar = {
    name:'Beneficio',
    Servicio: 'Servicio Estar 1',
    Servicio2: 'Servicio Estar 2',
    Servicio3: 'Servicio Estar 3',
    Servicio4: 'Servicio Estar 4',
    Servicio5: 'Servicio Estar 5',
    Servicio6: 'Servicio Estar 6',
}
export const infoSeguroCaracas = {
    name:'Beneficio',
    Servicio: 'Servicio Seguro Caracas 1',
    Servicio2: 'Servicio Seguro Caracas 2',
    Servicio3: 'Servicio Seguro Caracas 3',
    Servicio4: 'Servicio Seguro Caracas 4',
    Servicio5: 'Servicio Seguro Caracas 5',
    Servicio6: 'Servicio Seguro Caracas 6',
}