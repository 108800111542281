import { useContext } from "react"
import Steps from "../componentes/Steps/Steps"
import { AuthContext } from "../context/StateStep"
import { titlePage } from "../locales/es-es"
import Step3 from "./pages/Step3"
import Step4 from "./pages/Step4"
import Step1 from "./pages/Step1"

const Solicitudes = () => {
    const [stepsState, setStepsState, dataForm, setDataForm, optionsBank, setOptionsBank, optionsAsegura, setOptionsAsegura] = useContext(AuthContext)
    return (
        <>
            <div className="min-h-full bg-[#f3f4f6]">
                <div className="py-10">
                    <header>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <h1 className="text-3xl font-bold leading-tight text-gray-900">{titlePage.t1}</h1>
                        </div>
                    </header>
                    <main>
                        <div className="max-w-[90rem] mx-auto sm:px-6 lg:px-8">
                            <div className="mt-6">
                                <Steps />
                            </div>

                            <div className="px-4 py-8 sm:px-0">
                                <div className="bg-white min-h[70vh] shadow-lg ring-1 ring-black ring-opacity-5 py-16 px-4 overflow-hidden sm:px-6  lg:p-[40px] " >
                                    {stepsState.option1 && (<Step1 />)}
                                    {stepsState.option2 && (<Step3 />)}
                                    {stepsState.option3 && (<Step4 />)}
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}

export default Solicitudes
