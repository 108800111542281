import React, { useContext, useEffect, useState } from 'react'
import '../../componentes/View/shadow.css'
import InputTypeRadio from '../../componentes/Inputs/InputRadio/InputTypeRadio';
import InputSimple from '../../componentes/Inputs/InputText/InputSimple';
import { AuthContext } from '../../context/StateStep';
import { useForm } from '../../hooks/useForm';
import { textStep1, t_Button } from '../../locales/es-es'
import { InitialState } from '../../propState/InitialState';
import { validateStep1 } from '../../utils/validations/formValidations';
import ButtonSteps from '../../componentes/Elements/buttons/ButtonSteps'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ValidateAges } from '../../utils/validations/validateAges';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'

const Step1 = () => {
    const { initialForm } = InitialState()

    const { validateMercantil } = ValidateAges()
    const [stepsState, setStepsState, dataForm, setDataForm, optionsBank, setOptionsBank, optionsAsegura, setOptionsAsegura, dataBase, setDataBase] = useContext(AuthContext)
    const { form, errors, handleChange, handleBlur, handleSubmit } = useForm(initialForm, validateStep1)
    const [usePhone, setUsePhone] = useState(dataForm?.phone)
    const [errorPhone, setErrorPhone] = useState(false)


    const optionBank = {
        option1: [
            { id: 0, title: dataBase[2]?.name, img: 'https://www.segurosmcr.com/wp-content/uploads/2021/08/Seguros-Mercantil-v2.png' },
            { id: 1, title: dataBase[0]?.name, img: 'https://www.estarseguros.com/img/logo.png' },
            { id: 2, title: dataBase[1]?.name, img: 'https://www.seguroscaracas.com/portal/paginasv4/images/logo_seguros_caracas_nuevo_2.png' },
        ],
    }

    const netxPaso1 = (e) => {
        const res = handleSubmit(e, 'step 1', usePhone)
        if (res === 'ok' || (dataForm.back && res === 'espere')) {
            if (dataForm.back) {
                setOptionsBank(optionBank.option1)
                setStepsState({ ...stepsState, option1: false, option2: true, option3: false, })
            } else {
                setOptionsBank(optionBank.option1)
                setStepsState({ ...stepsState, option1: false, option2: true, option3: false, })
            }
        }
    }
    useEffect(() => { AOS.init({}); }, [])
    useEffect(() => {
    }, [usePhone])

    return (
        <div className="relative  md:w-full mx-auto">
            <form onSubmit={handleSubmit} className="md:flex gap-6 md:flex-col gap-y-4 sm:grid md:gap-10 sm:grid-cols-2 sm:gap-x-8">
                <div className='md:flex  md:gap-5'>
                    <InputSimple errorAnimation={"fade-in"} animation={'fade-up'} animationPlacement={'top-bottom'} animationDuration={'400'} title={textStep1.nombre} handleBlur={handleBlur} type={'text'} name={'name'} value={form.name} onChange={handleChange} errorText={errors.name} />
                    <InputSimple title={textStep1.cedula} animation={'fade-up'} animationPlacement={'top-bottom'} animationDuration={'600'} errorAnimation={"fade-in"} handleBlur={handleBlur} type={'number'} name={'cedula'} value={form.cedula} onChange={handleChange} errorText={errors.cedula} />
                </div>
                <div className='md:flex  md:gap-5' >
                    <InputSimple title={textStep1.email} animation={'fade-up'} animationPlacement={'top-bottom'} animationDuration={'800'} errorAnimation={"fade-in"} handleBlur={handleBlur} type={'email'} name={'email'} value={form.email} onChange={handleChange} errorText={errors.email} />
                    <div className='w-full grid' >
                        <label htmlFor="first-name" className="block text-lg font-medium text-gray-900">
                            Número de telefono
                        </label>
                        <PhoneInput
                            country={'ve'}
                            onBlur={(e) => handleBlur(e, 'phone')}
                            buttonStyle={{}}
                            searchStyle={{ border: 'none', background: 'none' }}
                            containerClass='p-0 block w-full shadow-sm  rounded-md'
                            inputClass='focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 y-3 px-4 block w-full shadow-sm'
                            inputStyle={{ width: '100%', border: 'none', background: 'none' }}
                            onChange={(v, c, e, f) => {
                                setUsePhone({ usePhone: e.target.value });
                                handleChange(e, 'phone');
                            }}
                            masks={{ ve: '(...) ...-....' }}
                        />
                        {errors.phone && <span data-aos='fade-up' className='text-red-500  bottom-[-30px] pt-4 uppercase text-[10px] font-bold'> {errors.phone} </span>}
                    </div>
                </div>
                <InputSimple title={textStep1.date} animation={'fade-up'} animationPlacement={'top-bottom'} animationDuration={'1200'} errorAnimation={"fade-in"} handleBlur={handleBlur} type={'date'} name={'date'} value={form.date} onChange={handleChange} errorText={errors.date} messageAge={dataForm?.ageNow} />
                <div>
                    <ul className="grid grid-cols-2 gap-x-5">
                        <li className="relative">
                            <InputTypeRadio label={'Hombre'} animation={'fade-up'} animationPlacement={'top-bottom'} animationDuration={'1400'} defaultValue={dataForm?.sex} type={'radio'} name={'sex'} handleBlur={handleBlur} onChange={handleChange} value={'Hombre'} id={'answer_yes'} error={errors.sex} htmlFor={'answer_yes'} />
                        </li>
                        <li className="relative">
                            <InputTypeRadio label={'Mujer'} animation={'fade-up'} animationPlacement={'top-bottom'} animationDuration={'1500'} defaultValue={dataForm?.sex} type={'radio'} name={'sex'} handleBlur={handleBlur} onChange={handleChange} value={'Mujer'} id={'answer_no'} error={errors.sex} htmlFor={'answer_no'} />
                        </li>
                    </ul>
                </div>
                <div className="sm:col-span-2">
                    <div className='w-2/6 float-right' data-aos='fade-up' data-aos-anchor-placement='top-bottom' data-aos-duration='1600' >
                        <ButtonSteps nameButton={t_Button.siguiente} netxSteps={(e) => netxPaso1(e)} />
                    </div>
                </div>
            </form>
        </div>
    )
}
export default Step1