import { getFirestore, collection, getDocs, addDoc, serverTimestamp } from 'firebase/firestore'
import firebaseApp from './config';
const db = getFirestore(firebaseApp);


export const getDate = async (nameCollection) => {
    const response = { status: true, error: null, products: [], startProduct: null }

    try {
        const res = await getDocs(collection(db, nameCollection))
        res.forEach(doc => {
            const product = doc.data()
            product.id = doc.id
            response.products.push(product)
        })

    } catch (error) {
        response.error = error
    }

    return response

}

export const sendCustomEmail = async (email, subject, body)=> {
	try{
    const collectionRef = collection(db, 'mail');
	const emailContent = {
		to: email,
		message: {
			subject: subject,
			text: body,
			html: `<p>${body}</p>`,
		},
	};
	console.log('listo para ser enviado');
	return await addDoc(collectionRef, emailContent);
} catch(error){
    console.log("Error al enviar el correo" + error);
}
}

export const sendDate = async (nameCollection, data) => {

    const result = { statusRespon: true, error: null }
    try {
        console.log("inicioi datos..........");
        console.log(data);
        console.log("inicioi 111111..........");

        let obj = {
            fullName: data.name,
            cedula: data.cedula,
            email: data.email,
            phone: data.phone.usePhone, 
            sex: data.sex,
            insuranceCarrier: data.banco.title,
            sumAssured: data.aseguradora.sumaAsegurada,
            sumMonth: data.aseguradora.montoXMes,
            quarterlySum: data.aseguradora.montoTriMes,
            sumYear: data.aseguradora.montoXAno,
            create: serverTimestamp(),
            idQuotation: data.idQuotation,
            salud: '',
        }
        console.log("🚀 ~ sendDate ~ obj:", obj)
        await addDoc(collection(db, nameCollection), obj)
    } catch (error) {
        result.statusRespon = false
        result.error = error
        console.log(error);
    }
    return result

}