export const parseNumber = (string) => {

    const to = string.toString()
    var number2 = '';
    var count = 0;
    for (let i = to.length; i > 0; i--) {
        if (count == 3) {
            count = 0
            number2 = ',' + number2
        }
        number2 = to[i - 1] + number2
        count++
    }
    return number2;
}