export const titlePage = {
    t1: 'Cotizador',
    descriptions1: 'Se refiere a que el Asegurado no padezca o tenga secuelas o complicaciones de alguna o varias de las siguientes enfermedades crónicas: Accidente Cerebro Vascular/Ictus, Infarto al Miocardio, EsclerosisMúltiples, Cáncer, Sida, Insuficiencia Renal Crónica /Diálisis.',
    descriptions2: 'En caso de falsedades y reticencias de mala fe por parte del Tomador, del Asegurado o del Beneficiario en la reclamación del siniestro, debidamente probadas, serán causa de nulidad absoluta del contrato y exonerarán del pago de la indemnización al Asegurador.'
};
export const textStep1 = {
    nombre: 'Nombre y Apellido',
    cedula: 'Cédula de Identidad',
    email: 'Email',
    phone: 'Número de teléfono',
    date: 'Fecha de nacimiento',
    salud: 'Salud',
    noSalud: '¿Por qué no gozas de buena salud?'
};
export const t_Button = {
    siguiente: 'Siguiente',
    atras: 'Atrás',
    accept: 'Cotizar',
    otros:'Solicitar otra cotizacion',
}

export const t_body = {
    edad: 'Tu edad es de',
    anos: 'años',
}

export const errorValidations = {
    campo: 'campo obligatorio',
    name: 'Solo letras y espacios',
    email: 'Debes ingresar un correo valido',
    date: 'Ingrese una edad adecuada'
};




