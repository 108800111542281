import { CheckIcon } from '@heroicons/react/24/solid'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../context/StateStep'

const steps = [
    { id: '01', name: 'Datos Personales', href: '#', status: 'complete' },
    // { id: '02', name: 'Políticas', href: '#', status: 'current' }, 
    { id: '02', name: 'Selección de Seguro', href: '#', status: 'upcoming' },
    { id: '03', name: 'Resumen', href: '#', status: 'upcoming' },
]
const StepState = () => {
    const [stepsState, setStepsState, dataForm, setDataForm, optionsBank, setOptionsBank, optionsAsegura, setOptionsAsegura] = useContext(AuthContext)
    return (
        <nav aria-label="Progress">
            <ol role="listitem" className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
                {steps.map((step, stepIdx) => (
                    <li key={step.name} className="relative md:flex-1 md:flex">
                        {(step.id === '01' && !stepsState.option1) || (step.id === '02' && !stepsState.option2 && !stepsState.option1) || (step.id === '03' && !stepsState.option3 && !stepsState.option2 && !stepsState.option1) ? (
                            <Link to={step.href} className="group flex items-center w-full">
                                <span className="px-6 py-4 flex items-center text-sm font-medium">
                                    <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-[#0f36e5] rounded-full group-hover:bg-indigo-800">
                                        <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                                    </span>
                                    <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                                </span>
                            </Link>
                        ) : (step.id === '01' && stepsState.option1) || (step.id === '02' && stepsState.option2 && !stepsState.option1) || (step.id === '03' && !stepsState.option4 && stepsState.option3 && !stepsState.option2 && !stepsState.option1) || (step.id === '04' && !stepsState.option4 && !stepsState.option3 && !stepsState.option2 && !stepsState.option1) ? (
                            <Link to={step.href} className="px-6 py-4 flex items-center text-sm font-medium" aria-current="step">
                                <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-[#0f36e5] rounded-full">
                                    <span className="text-[#0f36e5]">{step.id}</span>
                                </span>
                                <span className="ml-4 text-sm font-medium text-[#0f36e5]">{step.name}</span>
                            </Link>
                        ) :
                            (step.id === '01' && stepsState.option1) || (step.id === '02' && stepsState.option2 && !stepsState.option1) || (step.id === '03' && !stepsState.option3 && !stepsState.option2 && !stepsState.option1) || (step.id === '04' && stepsState.option4 && !stepsState.option3 && !stepsState.option2 && !stepsState.option1) ? (
                                <Link to={step.href} className="px-6 py-4 flex items-center text-sm font-medium" aria-current="step">
                                    <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-[#0f36e5] rounded-full">
                                        <span className="text-[#0f36e5]">{step.id}</span>
                                    </span>
                                    <span className="ml-4 text-sm font-medium text-[#0f36e5]">{step.name}</span>
                                </Link>
                            )
                                : (
                                    <Link to={step.href} className="group flex items-center">
                                        <span className="px-6 py-4 flex items-center text-sm font-medium">
                                            <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                                                <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                                            </span>
                                            <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                                        </span>
                                    </Link>
                                )}

                        {stepIdx !== steps.length - 1 ? (
                            <>
                                <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                                    <svg
                                        className="h-full w-full text-gray-300"
                                        viewBox="0 0 22 80"
                                        fill="none"
                                        preserveAspectRatio="none"
                                    >
                                        <path
                                            d="M0 -2L20 40L0 82"
                                            vectorEffect="non-scaling-stroke"
                                            stroke="currentcolor"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                            </>
                        ) : null}
                    </li>
                ))}
            </ol>
        </nav>
    )
}

export default StepState
